.div-slider-button-jump {
  height: 10vh;
  width: 40vh;
  cursor: pointer;
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.landing-coursel-heading-active {
  color: white;
  font-size: 1.5rem !important;
  margin: 0px !important;
}

.landing-coursel-heading {
  font-size: 1.5rem !important;
  color: rgba(234, 234, 234, 0.691);
  margin: 0px !important;
}

.div-slider-button-jump span {
  margin: 0 !important;
  height: 10%;
  padding-left: 3vh;
  width: 100%;
  display: flex;
  font-size: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
}

.div-slider-button-jump-timer {
  height: 3px;
  width: 38vh;
  border-radius: 10vh;
  background-color: rgba(255, 255, 255, 0.814);
}
.div-slider-button-jump-timer-inner-active {
  height: 100%;
  background-color: rgb(63, 127, 255);
  border-radius: 10vh;
  animation: lineupanimation 5s linear;
}
@keyframes lineupanimation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.div-slider-button-jump-timer-inner {
  display: none;
}

.img-absolute {
  overflow: hidden;
  margin-top: 10vh;
  height: 75vh !important;
  width: 100vw !important;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: -10vh;
}

.main-slider-option-container {
  position: absolute;
  top: 60vh;
  height: 10vh;
  width: 100vw;
  display: flex;
  justify-content: space-around;
}

.react-slider {
  display: none;
}

.react-current-slider {
  padding: 10vh 10vh 0vh 0vh;
  height: 80vh;
  width: 100vw;
  display: block;
  position: relative;
}

.slide-heading {
  margin-top: 5vh;
  margin-bottom: 5vh;
  position: relative;
  z-index: 1;
  color: white;
  animation: textfadein 0.7s ease-in, appearin 0.5s ease-in-out;
  animation-fill-mode: forwards;
  padding-top: 5vh;
  padding-left: 13vh;
  font-size: 3rem;
  font-family: "Barlow", sans-serif !important;
}

.slide-text {
  height: fit-content;
  animation-fill-mode: forwards;
  position: relative;
  z-index: 1;
  color: white;
  animation: textfadein 0.7s ease-in, appearin 0.5s ease-in-out;
  padding-left: 13vh;
  font-size: 1rem;
  line-height: 1.4rem;
  word-spacing: 0.13rem;
  letter-spacing: 0.03rem;
  font-weight: 500;
  width: 45vw;
  font-family: "Barlow", sans-serif !important;
}

@keyframes appearin {
  0% {
    transform: translateY(3vh);
  }

  100% {
    transform: translateY(0vh);
  }
}

@keyframes textfadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}





@media (max-width:480px) 
 {

  .react-current-slider {
    padding: 0px;
    height: fit-content;
  }
  
  .img-absolute {
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }

.slide-heading {
  padding: 0px;
  height: 35vh;
  font-size: 2.3rem;
  padding: 2vh;
  padding-top: 15vh;

}

.slide-text {
  width: 100%;
  padding: 2vh;
  text-align: justify;
}

.div-home-main-container-part-one
{
  height: 100vh;
}

.main-slider-option-container 
{

  display: none;
  
 }
 .div-slider-button-jump-timer 
 {
  height: 0px;
  width: 0px;
  
 }


.landing-coursel-heading
{
  display: none;
  width: 0px;
}

.div-slider-button-jump-timer-inner-active {
  height: 3px;
  width: 38vh;
  background-color: rgb(63, 127, 255);
  border-radius: 10vh;
  animation: lineupanimation 5s linear;
}

.div-slider-button-jump 
{
  width: fit-content;
}
}
