
.outer-main-container-navbar
{
    font-family: 'Barlow', sans-serif !important;
    position: fixed;
    height: 12vh;
    width: 100vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.3s ease-in-out;
    z-index: 999999;
    padding-left: 10vh;



}
.outer-main-container-navbar-logo
{
    height: 7.5vh;
    width: 17vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;


}

.outer-main-container-navbar-logo-ham
{
    height: 7.5vh;
    width: 17vh;
    margin-left: 1vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    image-rendering: -webkit-optimize-contrast;


}



.outer-main-container-navbar-logo-outer
{
    height: 10vh;
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}
.outer-main-container-navbar-links-outer
{
    height: 80px;
    width: 100vw;
    display: flex;
    align-items: center;
    position: relative;
    top: -1.5px;
}

.outer-main-container-navbar-links-inner
{
    height: 7vh;
    width: 95%;
    display: flex;
    align-items: center;
    padding-left: 13vh;
    justify-content: flex-start;
}

.outer-main-container-navbar-links-inner-end
{
   float: right;
   padding-right: 10vh;
}
nav
{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navbar-items
{
    height: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.slider
{
    display:inline-block;
    height: 2px;
    width: 0vw;
    border-radius: 50px;
    background-color: rgb(255, 255, 255);
    transition: width .5s;

}
.wrapper
{
    height: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Barlow', sans-serif !important;

}

.hamburger-links
{
    text-decoration: none;
    color: black;
}

.wrapper-end
{
    height: 6vh;
    width: 17vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1454D8;
    border-radius: 10vh;
    transition: 0.4s all ease;
    color: white;
    font-family: BigJhon-bold;
    font-weight: 700 !important;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
}


.wrapper-end-ham
{
    margin-top: 1vh;
}
.wrapper-end:hover
{
    transform: scale(1.1);
    transition: 0.4s all ease;
}

.navbar-items-links
{
    height: 4vh;
    width: 8vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.03rem;
    font-family: 'Barlow', sans-serif !important;
}

.navbar-items-links-contact
{
    height: 4vh;
    width: 8vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
    font-size: 2.3vh;
    font-weight: 800;
    letter-spacing: 0.03rem;
    font-family: 'Barlow', sans-serif !important;
}


.navbar-items-links span
{
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    transform-origin: center left;

}

.wrapper:hover .slider 
{
    width: 5vw;
    
}



.active 
{

    color: white;
    text-decoration: none;
}

.not-active
{
    text-decoration: none;
    color: white;
}




    .main-container-navbar
    {
        height: 12vh;
        width: 100vw;
        display: flex;
        align-items: center;
        -webkit-tap-highlight-color: transparent !important;
    }

    .main-container-image
    {
        height: 60%;
        width: 40%;
        background-image: url(./images/logotwo.webp);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center; 
        image-rendering: -webkit-optimize-contrast;
    }

    .main-container-navbar-menu
    {
        width: 100vw;
        position: absolute;
        top: 12vh;
        background-color: white;
        transition: 0.4s cubic-bezier(.31,.82,.52,.99);
        z-index: 99;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .spaner
    {
    height: 25vh;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    animation: textfadein 1s ease-in;
    animation-fill-mode: forwards;
    }

      @keyframes textfadein {
        0% {
          opacity: 0;
        }
        20% {
            opacity: 0;
          }
      
        100% {
          opacity: 1;
        }
      }

      
    .main-container-nav-hamburger
    {
        height: 60%;
        width: 60%;
        padding-right: 10%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    
    }
    






    .menu-btn
    {
        position: relative;
        display: flex;
        align-items: center;
        height: 30px;
        width:  30px;
        cursor: pointer;
        transition: all  0.5s ease-in-out;
    }
    
    .check-button-close
    {
        width: 15px;
        height: 2px;
        background-color: rgb(0, 0, 0);
        border-radius: 100px;
        transition: all .5s ease-in-out;
    }

    .menu-btn:hover .check-button-close
    {
        width: 17px;
        height: 2px;
        background-color: rgb(0, 0, 0);
        border-radius: 100px;
        transition: all .5s ease-in-out;
    }

    .check-button-close::before,.check-button-close::after
    {
        content: "";
        position: absolute;
        width: 25px;
        height: 2px;
        background-color: rgb(0, 0, 0);
        border-radius: 100px;
        transition: all 0.5s ease-in-out;
    }

    .menu-btn:hover .check-button-close::before
    {
        content: "";
        position: absolute;
        width: 25px;
        height: 2px;
        background-color: rgb(0, 0, 0);
        border-radius: 100px;
    }
    .menu-btn:hover .check-button-close::after
    {
        content: "";
        position: absolute;
        width: 25px;
        height: 2px;
        background-color: rgb(0, 0, 0);
        border-radius: 100px;
    }
    
    .check-button-close::before
    {
        transform: translateY(-6px);
    }
    .check-button-close::after
    {
        transform: translateY(6px);
    }     

    .check-button-open
    {
        width: 0%;
        height: 2px;
        background-color: rgb(0, 0, 0);
        border-radius: 100px;
        transition: all .5s ease-in-out;
    }
    .check-button-open::before,.check-button-open::after
    {
        content: "";
        position: absolute;
        width: 80%;
        height: 2px;
        background-color: rgb(0, 0, 0);
        border-radius: 100px;
        transition: all 0.5s ease-in-out;
    }
    .check-button-open::before
    {
        transform: translateY(0px);
        transform: rotate(45deg);
    }
    .check-button-open::after
    {
        transform: translateY(0px);
        transform: rotate(-45deg);
    }     




    






















    



    .navbar-items-links-ham
{
    height: 4vh;
    width: fit-content;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.03rem;
    font-family: 'Barlow', sans-serif !important;
}