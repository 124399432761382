.main-contact-landing-page {
  height: 80vh;
  width: 100vw;
  background: rgb(12,51,139);
  background: linear-gradient(102deg, rgba(12,51,139,1) 0%, rgba(10,91,185,1) 100%);
  display: flex;
  background-position: center;
  color: white;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-size: 6rem;
}

.main-contact-landing-page-title {
  margin: 0px;
  padding-top: 40vh;
  font-size: 3rem;
  padding-left: 20vh;
  color: white;
  height: 55vh;
  display: flex;
  padding-left: 7vw;
  flex-direction: column;
  justify-content: flex-end;
  text-shadow: #000000 3px 3px;
  text-align: left;
  width: 100%;
  font-size: 8rem;
  line-height: 8rem;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
}

.main-contact-landing-Stay-Connect {
  height: 30vh;
  width: 100%;
  color: black;
  font-size: 1.3rem;
  padding-left: 20vh;
  display: flex;
  align-items: flex-end;
}

.main-contact-landing-Stay-Connect-title {
  height: 7vh;
  width: 25vh;
  display: flex;
  align-items: center;
  padding-left: 2px;
  border-radius: 1vh;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #075bb9;
}

.main-contact-landing-get-in-touch {
  height: 10vh;
  width: 100%;
  padding-left: 20vh;
  display: flex;
  align-items: flex-end;
  font-size: 4rem;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  background: -webkit-linear-gradient(
    0deg,
    #075bb9 0%,
    #0968d5 35%,
    #2ea1ff 76%,
    #054186 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.right_conatct_social_icon {
  background: linear-gradient(to top right, #1325e8 -5%, #8f10b7 100%);
}
.contact_us {
  background-color: #f1f1f1;
  padding: 120px 0px;
}

.contact_inner {
  background-color: #fff;
  position: relative;
  box-shadow: 20px 22px 44px #cccc;
  border-radius: 25px;
}
.contact_field {
  width: 10vh;
}
.right_conatct_social_icon {
  height: 100%;
}

.contact_field h3 {
  color: #000;
  font-size: 40px;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 10px;
}
.contact_field p {
  color: #000;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 35px;
}
.contact_field .form-control {
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid #ccc;
}
.contact_field .form-control:focus {
  box-shadow: none;
  outline: none;
  border-bottom: 2px solid #1325e8;
}
.contact_field .form-control::placeholder {
  font-size: 13px;
  letter-spacing: 1px;
}

.contact_info_sec {
  position: absolute;
  background-color: #2d2d2d;
  right: 1px;
  top: 18%;
  height: 340px;
  width: 340px;
  padding: 40px;
  border-radius: 25px 0 0 25px;
}
.contact_info_sec h4 {
  letter-spacing: 1px;
  padding-bottom: 15px;
}

.info_single {
  margin: 30px 0px;
}
.info_single i {
  margin-right: 15px;
}
.info_single span {
  font-size: 14px;
  letter-spacing: 1px;
}

.button.contact_form_submit {
  background: linear-gradient(to top right, #1325e8 -5%, #8f10b7 100%);
  border: none;
  color: #fff;
  padding: 10px 15px;
  width: 100%;
  margin-top: 25px;
  border-radius: 35px;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 2px;
}
.socil_item_inner li {
  list-style: none;
}
.socil_item_inner li a {
  color: #fff;
  margin: 0px 15px;
  font-size: 14px;
}
.socil_item_inner {
  padding-bottom: 10px;
}

.map_sec {
  padding: 50px 0px;
}
.map_inner h4,
.map_inner p {
  color: #000;
  text-align: center;
}
.map_inner p {
  font-size: 13px;
}
.map_bind {
  margin-top: 50px;
  border-radius: 30px;
  overflow: hidden;
}

.main-container-contact-form 
{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.contact-form {
  width: 80vw;
  display: flex;
  justify-content: space-between;

  background: #fff;
  margin: 50px 0;
  height: 80vh;
  align-items: center;
}
.contact-form > * {
  width: 50%;
}
.contact-form .first-container {
  width: 50%;
  height: 90%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-form .second-container {
  padding: 4vh;
}
.contact-form .second-container h2 {
  font-size: 30px;
  font-weight: 400;
  color: #0090ea;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
}

#name-input {
  width: 49.9%;
}

#name-input-two {
  margin-left: 1.1%;
  width: 49.9%;
}
.contact-form .second-container form {
  display: flex;
  flex-direction: column;
}
.contact-form .second-container form .form-group {
  margin-bottom: 10px;
}
.contact-form .second-container form .form-group * {
  min-height: 55px;
  border: 1px solid #e6e6e6;
  padding: 0 20px;
}
.contact-form .second-container form .form-group label {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #e6e6e6;
  font-size: 16px;
  color: #333;
  text-transform: uppercase;
  margin-top: -1px;
}
.contact-form .second-container form .form-group:first-of-type input {
  width: 50%;
  margin-right: -5px;
}
.contact-form .second-container form .form-group input {
  width: 100%;
  font-size: 15px;
  margin-top: -2px;
}
.contact-form .second-container form .form-group input::placeholder,
.contact-form .second-container form .form-group textarea::placeholder {
  color: #999;
}
.contact-form .second-container form .form-group textarea {
  width: 100%;
  min-height: 80px;
  resize: none;
  padding: 10px 20px;
  margin-top: -1px;
}
.contact-form .second-container form .button {
  width: 200px;
  height: 50px;
  background-color: #1454d8;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  border: 0;
  border-radius: 2vh;
  position: relative;
  left: calc(50% - 100px);
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.contact-form .second-container form .button:hover {
  background: rgb(0, 0, 0);
  color: white;
  transition: 0.3s ease-in-out;
}

@media screen and (max-width: 800px) {
  .contact-form {
    width: 90vw;
  }
}
@media screen and (max-width: 700px) {
  .contact-form {
    flex-direction: column-reverse;
  }
  .contact-form > * {
    width: 100%;
  }
  .contact-form .first-container {
    padding: 40px 0;
  }
}

i {
  opacity: 0;
  font-size: 18px;
  color: #fff;
  will-change: transform;
  -webkit-transform: scale(0.1);
  transform: scale(0.1);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

h2 {
  pointer-events: none;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.container .card {
  position: relative;
  width: 500px;
  height: 200px;
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 4px;
}
.container .card:before {
  content: "";
  z-index: 99;
  position: absolute;
  top: -10px;
  left: 32px;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: #e6e5e1;
}
.container .card:after {
  content: "";
  z-index: 99;
  position: absolute;
  bottom: -10px;
  left: 32px;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: #e6e5e1;
}
.container .card ul {
  z-index: 99;
  position: absolute;
  left: 39px;
  top: 5px;
  list-style-type: none;
}
.container .card ul li {
  width: 2px;
  height: 2px;
  border-radius: 2px;
  margin: 6px 0;
  background-color: #e6e5e1;
}
.container .card h2 {
  z-index: 99;
  font-family: "Poppins", sans-serif;
  position: absolute;
  bottom: 0;
  right: 130px;
  font-size: 60px;
  font-weight: 700;
  color: #fff;
}
.container .card .fa-arrow-right {
  z-index: 100;
  position: absolute;
  right: 75px;
  bottom: 25px;
  font-size: 40px;
  cursor: pointer;
}
.container .card .pic {
  z-index: 100;
  width: 400px;
  height: 200px;
  background-image: url("https://images.unsplash.com/photo-1525543907410-b2562b6796d6?ixlib=rb-0.3.5&s=9ff8e5e718a6a40cbd0e1471235912f4&auto=format&fit=crop&w=3452&q=80");
  background-size: 100% 100%;
  filter: grayscale(100%);
}
.container .card .social {
  position: absolute;
  left: 60px;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 180px;
  height: 64px;
  border-radius: 80px;
}
.container .card .social i:nth-of-type(1) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.container .card .social i:nth-of-type(2) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.container .card .social i:nth-of-type(3) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.container .card .social i:nth-of-type(4) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.container .card:hover i {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.container .card .button {
  position: absolute;
  right: 14px;
  bottom: 14px;
  width: 30px;
  height: 30px;
  background-color: #da4d1d;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  mix-blend-mode: hard-light;
}
.container .card .button i {
  font-size: 3rem;
}
.container .card:hover .button {
  transform: scale(16.5);
}
.container .card:hover p {
  color: #fff;
}
.container .card:hover .pic {
  filter: grayscale(0);
}
.container .card2 .pic {
  background-image: url("https://images.unsplash.com/photo-1528785198459-ec50485704c7?ixlib=rb-0.3.5&s=3a2fc3039516555bbb2e9cd2967bd321&auto=format&fit=crop&w=1537&q=80");
}
.container .card2 .button {
  background-color: #2b26c3;
}

.dr {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 100px;
}

.main-contact-landing-Stay-Connect-wrapper {
  display: flex;
  height: 50vh;
  width: 100vw;
  justify-content: space-between;
}

.main-contact-landing-Stay-Connect-wrapper-one {
  height: 50vh;
  width: 90%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.main-contact-landing-Stay-Connect-wrapper-two {
  height: 60vh;
  width: 10vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.main-contact-email-wrapper {
  height: 10vh;
  width: 100vw;
  padding: 0vh 20vh 0vh 20vh;
  display: flex;
  justify-content: space-between;
}

.main-contact-email {
  height: 10vh;
  width: 20vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
}

.main-contact-email span:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.main-contact-email span:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}





@media (max-width:480px) 
 {

  .main-contact-landing-Stay-Connect-wrapper
  {
    height: fit-content;
  }


.main-contact-landing-Stay-Connect-wrapper-one {
  height: fit-content;
  width: 100%;
}
  .main-contact-landing-page
  {
    height: 30vh;
  }
  .main-contact-landing-page-title
  {
    margin: 0px;
    padding: 0vh;
    color: rgb(255, 255, 255);
    height: 30vh;
    display: flex;
    justify-content: center;
    font-size: 3rem;
    align-items: center;
  }


.main-container-contact-form 
{
  height: fit-content;
  width: 100vw;
}


.main-contact-email-wrapper {
  height: 45vh;
  width: 100vw;
  flex-direction: column;
  justify-content: space-around;
  padding: 4vh;
}

.main-contact-email
{
  padding: 1vh;
}

.main-contact-email {
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
}

.main-contact-landing-Stay-Connect-wrapper-two
{
  display: none;
}

.main-contact-landing-Stay-Connect {
  height: 10vh;
  width: 100%;
  color: black;
  font-size: 1.3rem;
  padding-left: 3vh;
  display: flex;
  align-items: flex-end;
}


.main-contact-landing-get-in-touch {
  height: fit-content;
  width: 100%;
  padding-left: 3vh;
  align-items: flex-end;
  font-size: 2.3rem;
}

.contact-form {
  width: 90vw;
  height: fit-content;
  flex-direction: column;
}
.contact-form .second-container {
  padding: 0vh;
  
}


#name-input {
  width: 100%;
}

#name-input-two {
  margin-left: 0%;
  width: 100%;
}
.contact-form .first-container {
  width: 70vw;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.main-contact-landing-Stay-Connect-title {
  height: fit-content;
  font-size: 1rem;
}

.main-contact-email span:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
}

.main-contact-email span:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}
 }