.main-container-cookie-container
{
    height: fit-content;
    width: 100vw;
    padding: 20vh;
    font-family: 'Barlow', sans-serif;

}

.cookie-ul-tag, .cookie-ol-tag
{
    margin-left: 4vh;

}

.cookie-li-tag
{
    font-size: 1rem;
    padding-top: 2.5vh;
    line-height: 1.5rem;
    word-spacing: 0.13rem;
    letter-spacing: 0.03rem;
    font-weight: 500;
    color: rgba(0,0,0,.87);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;

}

.cookie-p-tag
{
    font-size: 1rem;
    padding-top: 2.5vh;
    line-height: 1.5rem;
    word-spacing: 0.13rem;
    letter-spacing: 0.03rem;
    font-weight: 500;
    color: rgba(0,0,0,.87);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

.cookie-heading-tag-h3
{    font-size: 1.3rem;
    padding-top: 2.5vh;
    line-height: 1.5rem;
    word-spacing: 0.13rem;
    letter-spacing: 0.03rem;
    color: rgba(0,0,0,.87);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

.cookie-heading-tag-h1
{
    margin-top: 5vh;
    margin-bottom: 1vh;
    font-size: 2rem;
    
}
.cookie-heading-tag-h1-privacy
{
    height: fit-content;
    padding-bottom: 5vh;
    font-size: 3rem;
}

#cookie-link
{
    text-decoration: none;
    color: black;
}




@media (max-width:480px) 
 {
    .main-container-cookie-container
    {
    height: fit-content;
    width: 100vw;
    padding: 5vh 3vh 5vh 3vh;
    font-family: 'Barlow', sans-serif;
    text-align: justify;
    }
    .cookie-ul-tag, .cookie-ol-tag
    {
        margin-left: 4vh;
        text-align: justify;
    
    }
    .cookie-li-tag
    {
        font-size: 0.8rem;
        text-align: justify;
    
    }
    .cookie-p-tag
    {
        font-size: 0.8rem;
        text-align: justify;
        line-height: 1.1rem;
    }
    .cookie-heading-tag-h3
    {    font-size: 0.9rem;
        text-align: justify;
    }
    .cookie-heading-tag-h1
    {
        font-size: 1rem;
        text-align: justify;
    }
    .cookie-heading-tag-h1-privacy
    {
        font-size: 2rem;
        text-align: justify;
    }

 }