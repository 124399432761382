@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap");

.div-home-main-container-part-one-main {
  height: 80vh;
  width: 100vw;
  background: rgb(12,51,139);
  background: linear-gradient(102deg, rgba(12,51,139,1) 0%, rgba(10,91,185,1) 100%);
}

.home-main-part-one-heading-wrapper-two-contact {
  width: 50%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  padding-top: 12vh;
}

.home-main-part-one-heading-wrapper-text-section {
  display: flex;
  height: 100%;
  width: 100%;
  color: rgb(255, 255, 255);
  font-family: "Barlow", sans-serif;
  justify-content: space-around;
}

.about-main-part-one-heading {
  height: 55vh;
  display: flex;
  padding-left: 7vw;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  width: 100%;
  font-size: 8rem;
  line-height: 8rem;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  text-shadow: #000000 3px 3px;
}

.main-about-us-container {
  height: 25vh;
  width: 100vw;
  color: #075bb9;
  font-size: 1.7rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 5vh;
  font-family: "Barlow", sans-serif;
}

.main-about-us-container-facts {
  height: 15vh;
  width: 100vw;
  color: #075bb9;
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-family: "Barlow", sans-serif !important;
}

.main-about-us-main-container {
  height: 75vh;
  width: 100vw;
  display: flex;
  padding: 5vh 10vh 15vh 10vh;
}

.main-about-us-main-container-one {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main-about-us-main-container-one-heading {
  height: 10vh;
  width: 100%;
  font-size: 2rem;
  color: #075bb9;
}

.main-about-us-main-container-one-text {
  padding: 0vh 10vh 0vh 0vh;
  height: fit-content;
  width: 100%;
  font-size: 1.1rem;
  line-height: 1.8rem;
  letter-spacing: 0.03rem;
  word-spacing: 0.14rem;
}

.main-about-us-main-container-two {
  height: 100%;
  width: 50%;
  background-image: url(./images/workingpeople.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.main-about-us-container-facts-card {
  border-radius: 5vh;
  height: 40vh;
  width: 45vh;
  transition: 0.2s ease-in-out !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.2s ease-in-out;
}

.main-about-us-container-facts-card-icon-frame-one {
  height: 12vh;
  width: 12vh;
  background-image: url(./images/stopwatch.png);
  background-position: center;
  background-size: contain;
}

.main-about-us-container-facts-card-icon-frame-two {
  height: 12vh;
  width: 12vh;
  background-image: url(./images/chip.png);
  background-position: center;
  background-size: contain;
}

.main-about-us-container-facts-card-icon-frame-three {
  height: 12vh;
  width: 12vh;
  background-image: url(./images/padlock.png);
  background-position: center;
  background-size: contain;
}

.main-about-us-container-facts-card:hover {
  transform: scale(1.05);
  transition: 0.3s ease-in-out;
}

.main-about-us-container-facts-card-text {
  height: 12vh;
  padding: 0vh 5vh 1vh 5vh;
  width: 100%;
  font-size: 0.9rem;
  text-align: justify;
  line-height: 1.3rem;
}

.main-about-us-container-facts-card-heading {
  height: 9vh;
  padding: 3vh 4vh 2vh 4vh;
  width: 100%;
  color: #075bb9;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-about-us-container-facts-card-icon {
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-about-us-container-facts-text {
  height: fit-content;
  width: 100vw;
  font-family: "Barlow", sans-serif;
  font-size: 1.2rem;
  display: flex;
  align-items: flex-end;
  padding: 0vh 18vh 5vh 18vh;
  line-height: 1.5rem;
  word-spacing: 0.1rem;
}

.main-about-us-container-facts-container {
  height: fit-content;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.main-container-our-mission-and-vission {
  padding: 10vh 0vh 10vh 0vh;
  height: 170vh;
  width: 100vw;
  display: flex;
}

.main-container-our-mission-and-vission-img {
  width: 60%;
  height: 100%;
  background: linear-gradient(rgba(0, 33, 98, 0.9), rgba(0, 41, 123, 0.9)),
    url(./images/aboutusmission.jpeg);
  background-size: 120vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: white;
  padding: 0vh 20vh 0vh 12vh;
}

.main-container-our-mission-wrapper {
  height: 80%;
  width: 100%;
}

.main-container-our-mission-card {
  height: 60vh;
  width: 40vh;
  background-color: white;
  border-radius: 2vh;
  position: relative;
  left: -15vh;
  padding: 3vh 3vh 3vh 4vh;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main-container-our-values-card {
  height: 60vh;
  width: 40vh;
  background-color: white;
  border-radius: 2vh;
  position: relative;
  left: 30vh;
  top: -10vh;
  padding: 3vh 3vh 3vh 4vh;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main-container-our-mission-heading-icon-mission {
  height: 15vh;
  width: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 10vh;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./images/mission.png);
  border-radius: 10vh;
}
.main-container-our-mission-heading-icon-value {
  background-size: 10vh;
  background-position: center;
  background-repeat: no-repeat;
  height: 15vh;
  width: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(./images/diamond.png);
  border-radius: 10vh;
}

.main-container-our-mission-heading {
  height: 8vh;
  padding-top: 2vh;
  display: flex;
  align-items: center;
  color: rgb(0, 0, 0);
  font-weight: 500;
  font-size: 2.5rem;
}

.main-container-our-mission-line {
  height: 3px;
  width: 70%;
  background-color: #02c3ff;
  border-radius: 1vh;
  margin-top: 1vh;
  margin-bottom: 3vh;
}
.main-container-our-mission-text {
  height: 20vh;
  width: 90%;
  font-size: 1rem;
  word-spacing: 0.13rem;
  letter-spacing: 0.03rem;
  color: rgb(0, 0, 0);
  padding-left: 1vh;
  line-height: 1.5rem;
  font-family: "Barlow", sans-serif;
}

.main-container-our-mission-and-vission-text {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-main-container-countup {
  margin-top: 5vh;
  padding: 5vh 5vh 0% 5vh;
  height: 50vh;
  width: 100vw;
  display: flex;
  padding-bottom: 15vh;
}

.div-main-container-countup-part {
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
  color: rgb(0, 0, 0);
  font-family: "Barlow", sans-serif;
}

.div-main-container-countup-part-icon {
  display: flex;
  height: 50%;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  color: #1454d8;
}

.div-main-container-countup-part-count {
  height: 20%;
  text-align: center;
  font-size: 2rem;
}

.div-main-container-countup-part-text {
  display: flex;
  height: 15%;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0);
  font-size: 1.5rem;
  font-weight: 500;
}


.main-container-video-wrapper
{
  height: 60vh;
  width: 100vw;
  display: flex;
}

.main-container-pop-up-video 
{
  height: 100%;
  width: 50vw;
  display: flex;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 7vh;
}



.responsive-iframe {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 85%;
  border: none;
}

.main-container-pop-up-video-wrapper {
  height: fit-content;
  width: fit-content;
  display: flex;
  padding-left: 10vh;
}

.main-container-pop-up-video span {
  margin-right: 10vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.child2 {
  position: relative;
  top: 0.5vh;
  width: 0%;
  height: 3px;
  border-radius: 3px;
  transition: 0.5s cubic-bezier(0.16, 0.1, 0.64, 0.18);
  background-color: #1454d8;
  margin: 0px !important;
}
.main-container-pop-up-video-wrapper:hover .child2 {
  width: 100%;
  transition: 0.5s cubic-bezier(0.16, 0.1, 0.64, 0.18);
}

.main-container-pop-up-video-button-outer {
  cursor: pointer;
  border: 0px;
  height: 12vh;
  width: 12vh;
  background-color: #1454d8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  padding-left: 1vh;
  color: white;
  animation: buttonpulsing 1.1s ease-in-out infinite;
}

@keyframes buttonpulsing {
  0% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 rgb(20, 84, 216, 0.8);
  }

  80% {
    transform: scale(1);
    box-shadow: 0px 0px 5px 25px rgb(20, 84, 216, 0);
  }
  100% {
    transform: scale(0.8);
  }
}

.main-wrapper-error
{
  display: flex;
  align-items: center;
  
}

.input-section-div-error-about
{
  color: red;
  width: 49.9%;
  display: flex;
  height: 3vh;
  font-size: 0.8rem;
  margin-bottom: 1vh;
  padding-left: 1vh;
}




















@media (max-width:480px) 
 {

  
  .div-home-main-container-part-one-main {
    height: 30vh;
    width: 100vw;
    background: rgb(12,51,139);
    background: linear-gradient(102deg, rgba(12,51,139,1) 0%, rgba(10,91,185,1) 100%);
  }



.main-about-us-container 
{
  height: 13vh;
  padding: 3vh 3vh 2vh 3vh;
  font-size: 2rem;
  font-weight: 700;
  background: -webkit-linear-gradient(0deg, #075BB9 0%, #0968d5 35%, #2ea1ff 76%, #054186 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-main-part-one-heading {
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0vh;
  font-size: 4rem;
}



.about-main-part-one-heading span
{
  display:inline-block;
  font-size: 3rem;
}

.home-main-part-one-heading-wrapper-two-contact
{
  display: none;
}

.main-about-us-main-container {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0vh;
}

.main-about-us-main-container-one {
  width: 100%;
}

.main-about-us-main-container-one-heading {
  display: none;
}



.main-about-us-main-container-two 
{
  height: 30vh;
  width: 96vw;
  margin: 2vw;
}



.main-about-us-container-facts-text {
  text-align: justify;
  line-height: 1.3rem;
  letter-spacing: 0.03rem;
  word-spacing: 0.14rem;
  font-family: 'Barlow', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  padding: 2vh;
}


.main-about-us-main-container-one-text {
  text-align: justify;
  line-height: 1.3rem;
  letter-spacing: 0.03rem;
  word-spacing: 0.14rem;
  font-family: 'Barlow', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  padding: 2vh;
}


.main-about-us-container-facts {
  font-size: 2rem;
  font-weight: 700;
  background: -webkit-linear-gradient(0deg, #075BB9 0%, #0968d5 35%, #2ea1ff 76%, #054186 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.main-about-us-container-facts-container {
  height: fit-content;
  flex-direction: column;
}

.main-container-our-mission-and-vission {
  padding: 0vh;
  height: fit-content;
  flex-direction: column;
}

.main-container-our-mission-and-vission-img {
  height: 40vh;
  width: 100%;
  padding: 2vh;
  font-size: 1.4rem;
  text-align: center;
}
.main-container-our-mission-and-vission-text {
  height: 125vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 3vh;
}

.main-container-our-mission-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.main-container-our-mission-card {
  height: 57vh;
  width: 100%;
  position: relative;
  left: 0vh;
  padding: 3vh;
}

.main-container-our-values-card {
  height: 57vh;
  width: 100%;
  position: relative;
  left: 0vh;
  top: 0vh;
  padding: 3vh;
}


.main-container-our-mission-heading-icon-mission {
  height: 13vh;
}
.main-container-our-mission-heading-icon-value {
  height: 13vh;
}

.main-container-our-mission-text {
  height: 23vh;
  width: 100%;
  word-spacing: 0.13rem;
  letter-spacing: 0.03rem;
  color: rgb(0, 0, 0);
  padding: 1vh;
  line-height: 1.5rem;
  font-family: "Barlow", sans-serif;
  font-size: 0.9rem;
  word-spacing: 0.05rem;
  letter-spacing: 0.01rem;
  text-align: justify;
  line-height: 1.3rem;
}


.main-container-video-wrapper
{
  height: fit-content;
  flex-direction: column;
}

.main-container-pop-up-video 
{
  width: 100vw;
  padding: 5vh 0vh 0vh 0vh; 
  font-size: 2rem;

}

.main-container-pop-up-video-button-outer
{
  display: none;
}
.main-container-pop-up-video-wrapper {
  height: fit-content;
  width: fit-content;
  display: flex;
  padding: 0vh;
}
.main-container-pop-up-video span {
margin: 0vh;
}

.video-frame-youtube
{
  padding: 2vh;
}


.div-main-container-countup {
  margin-top: 5vh;
  padding: 0vh;
  height: 90vh;
  width: 100vw;
  margin-bottom: 5vh;
  flex-direction: column;
}

.div-main-container-countup-part {
  flex-direction: column;
  width: 100%;
}

.div-main-container-countup-part-icon {
  font-size: 3rem;
}

.div-main-container-countup-part-count {

  font-size: 1.3rem;
}

.div-main-container-countup-part-text {

  font-size: 1.3rem;
}
 }