@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@500&family=Nothing+You+Could+Do&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@800&display=swap');

.div-home-main-container-part-one
{
    width: 100vw;
    height: 80vh;
    position: relative;
    padding-top: 9vh;
}

.div-home-main-container-part-one-line-wrapper span
{
   width: 0px;
   position: relative;
   margin-top:vh;
   height: 1px;
   margin-top: 11vh;
   background-color: rgb(101, 101, 101);
   animation: expandhori 1s cubic-bezier(1,.01,.31,.92);
   animation-delay: 0.7s;
   animation-fill-mode: forwards;
   
}


@keyframes expandhori {
    0%
    {
        width: 0vh;
    }
    100%
    {
        width: 100vw;
    }
}

img{
    height: 100vh!important;
    width: 100vh!important;
}

.home-main-service-section
{
    margin-top: 15vh;
    padding-top: 7vh;
    height: 165vh;
    padding-bottom: 10vh;
    width: 100vw;
    background-size: 500vh;
    background: rgb(12,51,139);
    background: linear-gradient(102deg, rgba(12,51,139,1) 0%, rgba(10,91,185,1) 100%);
}

.home-main-service-section-heading
{
    height: 10vh;
    width: 100%;
    font-size: 7vh;
    font-weight: 700;
    text-align: left;
    padding-left: 15vh;
    color: white;
    font-family: 'Barlow', sans-serif;
    text-shadow: 2px 2px black;
}

.home-main-service-section-container
{
    padding-top: 7vh;
    height: 60vh;
    width: 100%;
}

.home-main-service-section-container-one
{
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-around;
    
    padding: 2.5vh 5vh 2.5vh 5vh;
    margin-bottom: 5vh;
}

.home-main-service-section-container-two
{
    margin-top: 5vh;
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 2.5vh 5vh 2.5vh 5vh;
}

.home-main-service-section-card
{
    height: fit-content;
    padding-bottom: 4vh;
    width: 40vh;
    background-color: white;
    border-radius: 3vh;
    font-family: 'Barlow', sans-serif;
    transition: 0.2s ease-in-out;
    box-shadow: 0 9px 15px rgba(0, 0, 0, 0.749);
}


.home-main-service-section-card:hover
{
    transform: scale(1.05);
    transition: 0.3s ease-in-out;
    box-shadow: rgb(0, 0, 0) 0px 11px 23px 3px;
}


.home-main-service-section-card-icon
{
    padding-top: 3vh;
    height: 23vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
}

.home-main-service-section-card-heading
{
    
    padding: 1vh 2vh 2vh 2vh ;
    height: 9vh;
    width: 100%;;
    font-weight: 500;
    font-size: 1.5rem;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.home-main-service-section-card-text
{
    padding: 0vh 3vh 0vh 3vh;
    text-align: center;
}

.home-main-service-section-card-button
{
    padding-top: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
   

}



.main-service-section-card-button-class
{
    border: 0px;
    border-radius: 2vh;
    width: 15vh;
    height: 5vh;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.749);
    font-size: 1rem;
    font-weight: 600;
    color: white;
    background-color: #1454D8;
    transition: 0.2s ease-in-out;
}

.main-service-section-card-button-class:hover
{
    transform: scale(1.05);
    transition: 0.4s ease-in-out;
    background-image: linear-gradient(0deg, #000000 0%, #0e0e0e 35%, #171717 76%, #0a0808 100%);
    
}
  




.main-about-us-container-facts-container-home 
{
    height: 80vh;
    width: 100vw;
    background-image: url(./images/section.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }













.main-home-service-area-one
{
    height: 50%;
    width: 100%;
    color: black;
    font-size: 3em;
    text-align: center;
    font-weight: 600;
    font-family: 'Barlow', sans-serif;
    display: flex;
    justify-content: space-around;
    margin: 10vh 0vh 10vh 0vh;
}

.main-home-service-area-two
{
    height: 50%;
    width: 100%;
    margin: 10vh 0vh 10vh 0vh;
    display: flex;
    justify-content: space-evenly;

}


.home-main-service-section-one-heading-title{
    
    height: 5vh;
    width: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3C65FB;
    font-size: 1.2rem;
    font-weight: 400;
    background-color: #dee4ff;
    border-radius: 1vh;
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
}

.home-main-about-section
{
  
    background-size: contain;
    height: 90vh;
    width: 100vw;
    padding: 15vh 0vh 0vh 0vh;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    justify-content: flex-start;
  

}


.home-main-about-section-one
{
  
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    height: 100%;
    width: 45vw;
    color: black !important;
    padding-left: 5vh;
    margin-right: 5vh;
    
}

.home-main-about-section-two
{
    position: relative;
    z-index: 1;
    height: 100%;
    width: 60vw;
    display: flex;
    align-self: center;
}



.home-main-about-section-two-img
{
    height:90%;
    width: 100%;
    background: url(./images/workingpeople.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}


.home-service-area-link
{    
    text-decoration: none;
}



.home-main-about-section-one-heading-title{
    
    height: 5vh;
    width: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 400;
    background-color:  #027BFE;
    border-radius: 1vh;
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
}



.home-main-about-section-one-heading-text
{
    margin: 0px !important;
    display: flex;
    align-items: center;
    height: fit-content;
    width: 100%;
    background: -webkit-linear-gradient(0deg, #075BB9 0%, #0968d5 35%, #2ea1ff 76%, #054186 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3rem;
    font-family: 'Barlow', sans-serif;
    font-weight: 700;
    padding: 5vh 0vh 0vh 5vh;
}

.home-main-about-section-one-text
{
    height:fit-content;
    width: 100%;
    line-height: 1.3rem;
    letter-spacing: 0.03rem;;
    word-spacing: 0.14rem;
    padding: 3vh 7vh 5vh 5vh;
    font-family: 'Barlow', sans-serif;
    font-size: 1rem;
    text-align: justify;
    color: rgb(0, 0, 0);
    font-weight: 500;

}




.home-aboutus-area-link-button
{
    border-radius: 10px;
    border: 0px;
    height: 5vh;
    width: 8vw;
    font-size: 1rem;
    background-color: #1454D8;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-weight: 800;
    font-family: 'Barlow', sans-serif;
    transition: 0.3s linear;
    
}

.home-aboutus-area-link
{
    text-decoration: none;

}


.home-aboutus-area-link-button:hover
{
    transform: scale(1.1);
    transition: 0.3s linear;
}

.home-aboutus-area-link-button:hover .CgArrowRight-aboutus
{
    transition: 0.1s linear;
    transform: scale(1.3);
}





.home-quotes-section-icon
{
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 7rem;
    color: #006bde;
    align-items: center;

}



.home-quotes-section-text
{
    display: flex;
    justify-content: center;
    height: 40%;
    width: 80%;
    background: -webkit-linear-gradient(0deg, #075BB9 0%, #0968d5 35%, #2ea1ff 76%, #054186 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2rem;
    font-family: 'Barlow', sans-serif;
    font-weight: 700;
    text-align: center;
   

}





.service-home-button:hover
{
    transform: scale(1.1);
    background-color: #027BFE;
    color: #ffffff;
    transition: 0.3s ease-in-out;
}


.main-home-help-section
{
    height: 35vh;
    width: 100vw;
    display: flex;

    font-family: 'Barlow', sans-serif !important;
}

.main-home-help-section-one
{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding:10vh 10vh 0vh 10vh;
}

.main-home-help-section-two
{
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 10vh;

}
.main-home-help-section-modren-way-heading
{
    font-size: 3rem;
    font-weight: 700;
    background: -webkit-linear-gradient(0deg, #075BB9 0%, #0968d5 35%, #2ea1ff 76%, #054186 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.main-home-help-section-modren-way-text
{
    font-size: 1.2rem;
    padding-top: 2.5vh;
    line-height: 1.7rem;
    word-spacing: 0.13rem;
    letter-spacing: 0.03rem;
}


.home-main-simple-way
{
    width: 100vw;
    display: flex;
    font-family: 'Barlow', sans-serif !important;
    align-items: center;
}

.home-main-simple-way-section-one
{
    height: 100%;
    width: 50%;
    padding-left: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
    

}
.home-main-simple-way-section-one-heading
{
    font-size: 3.5rem;
    font-weight: 700;
    padding-bottom: 2.5vh;
    background: -webkit-linear-gradient(0deg, #075BB9 0%, #0968d5 35%, #2ea1ff 76%, #054186 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home-main-simple-way-section-one-text
{
    font-size: 1.2rem;
    padding-top: 2.5vh;
    line-height: 1.4rem;
word-spacing: 0.13rem;
letter-spacing: 0.03rem;
}

.home-main-simple-way-section-two
{
    height: 100%;
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
}



.section-experience-home
{
    height: 80vh;
    width: 100vw;
    display: flex;
}

.section-experience-home-one
{
    height: 100%;
    width: 50%;
    font-size: 10vh;
    font-weight: 700;
    padding-bottom: 2.5vh;
    background: -webkit-linear-gradient(0deg, #075BB9 0%, #0968d5 35%, #2ea1ff 76%, #054186 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-left: 10vh;
    display: flex;
    align-items: center;
}

.section-experience-home-two
{
    height: 100%;
    width: 50%;
    display: flex;
    padding-left: 10vh;
    

}


.section-experience-home-two-span-one
{
    height: 35vh;
    width: 35vh;
    z-index: 3;
    background: rgb(0,56,199);
    border-radius: 4vh;
    background-color: #d4418e;
    background-image: linear-gradient(315deg, #d4418e 0%, #0652c5 74%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.749);
    transition: 0.2s ease-in-out;

}

.section-experience-home-two-span-two
{
    background-color: #2a2a72;
    background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);
    height: 35vh;
    width: 35vh;
    border-radius: 4vh;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.749);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.2s ease-in-out;
}

.section-experience-home-two-span-three
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #2876f9;
    background-image: linear-gradient(315deg, #2876f9 0%, #6d17cb 74%);
    height: 35vh;
    width: 35vh;
    position: relative;
    border-radius: 4vh;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.749);
    transition: 0.2s ease-in-out;

}

.section-experience-home-two span:nth-child(1)
{
    width: 100%;
    height: 55%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 1vh;
    color: white;
    font-size: 4.5rem;
    font-weight: 700;

}
.section-experience-home-two span:nth-child(2)
{
    width: 100%;
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: white;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    padding: 2vh 1vh 0vh 1vh;
}


.section-experience-home-two-span-one:hover 
{
    transform: scale(1.05);
    transition: 0.3s ease-in-out;
}
.section-experience-home-two-span-two:hover
{
    transform: scale(1.05);
    transition: 0.3s ease-in-out;
}
.section-experience-home-two-span-three:hover
{
    transform: scale(1.05);
    transition: 0.3s ease-in-out;
}



.home-how-it-works
{
    height: 115vh;
    width: 100vw;
}

.home-how-it-works-heading
{
    font-size: 3rem;
    padding-top: 10vh;
    font-family: 'Barlow', sans-serif !important;
    text-align: center;
    color: rgb(0, 0, 0);
    font-weight: 600;
    padding-bottom: 5vh;
}

.home-how-it-works-container
{
    height: 55vh;
    width: 100vw;
    background-image: url(./images/Howitwork.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.home-main-traning-section-two-img
{
    height:100%;
    width: 100%;
    background-image: url(./images/study.webp);
    background-size: 140vh;
    background-repeat: no-repeat;
    background-position: center;
}

.home-how-it-works-detail-container
{
    height: 30vh;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10vh 12vh 0vh 12vh;
}

.home-how-it-works-detail-container-section
{
    height: 100%;
    width: 22%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.home-how-it-works-detail-container-section2
{
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.home-how-it-works-detail-container-section-heading
{
    margin: 0px;
    font-size: 1.4rem;
    font-weight: 500;
}

.home-how-it-works-detail-container-section-heading-para
{
    margin: 0px;
    font-size: 0.9rem;
    padding-top: 2.5vh;
    line-height: 1.4rem;
    word-spacing: 0.13rem;
    letter-spacing: 0.03rem;
    font-weight: 400;

}

.home-main-training-section-two
{
    position: relative;
    z-index: 1;
    height: 90%;
    width: 60vw;
    display: flex;
    align-self: center;
}



.home-main-training-section-one
{
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-self: center;
    height: fit-content;
    width: 45vw;
}




.main-container-why-love-our-service
{
    padding-left: 10vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    margin: 0px;
    height: fit-content;
    padding-bottom: 2vh;
    display: flex;
    align-items: center;
    font-size: 3rem;
    font-weight: 700;
    background: -webkit-linear-gradient(0deg, #075BB9 0%, #0968d5 35%, #2ea1ff 76%, #054186 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.main-container-why-love-our-service-text
{
    height: 10vh;
    width: 100vw;
    padding: 0vh 10vh 0vh 10vh;
    line-height: 1.5rem;
    word-spacing: 0.1rem;
    font-size: 1rem;
    margin: 0px;
    font-weight: 300;
}
.main-container-service-icon-and-heading
{
    height: 25vh;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    padding: 4vh 5vh 5vh 5vh ;
}

.main-container-service-icon-and-heading-wrapper
{
    height: 100%;
    width: 20%;

}

.main-container-service-icon
{
    height: 70%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.main-container-service-icon-img-one
{
    height: 10vh;
    width: 10vh;;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10vh;
    background-image: url(./images/experience.png);
}

.main-container-service-icon-img-two
{
    height: 10vh;
    width: 10vh;;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10vh;
    background-image: url(./images/refresh.png);
}

.main-container-service-icon-img-three
{
    height: 10vh;
    width: 10vh;;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10vh;
    background-image: url(./images/technical-support.png);
}


.main-container-service-heading
{
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.new-service-logo
{
    height: 25vh;
    width: 30vh;
}

.main-container-wrapper-exp
{
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}


.main-container-wrapper-exp-one
{
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

























@media (max-width:480px) 
 {

    .section-experience-home
{
    padding-top: 10vh;
    padding-bottom: 10vh;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.section-experience-home-one
{
    height: 100%;
    width: 50%;
    font-size: 3vh;
}
.section-experience-home-two
{
    height: 100%;
    width: 50%;
    padding-bottom: 5vh;
    display:flex;
    flex-direction: column;


}












.home-main-about-section
{
  
    background-size: contain;
    height: fit-content;
    width: 100vw;
    padding: 15vh 0vh 0vh 0vh;
  

}


.home-main-about-section
{
    flex-direction: column;
  

}


.home-main-about-section-one
{
    width: 100%;
    padding: 2vh;
    justify-content: space-around;
    margin: 0px;
    
}
.home-main-about-section-two
{
    width: 100%;
}

.home-main-about-section
{
    height: fit-content;
    padding: 0px;
    margin-top: 0vh;
}



.home-main-about-section-one-heading-text
{
padding: 0px;
font-size: 2.5rem;
margin-bottom: 10vh;
text-align:center;
margin-bottom: 5vh !important;
}


.home-aboutus-area-link-button
{
    height: 6vh;
    width: 30vw;
    
}


.home-aboutus-area-link
{
    display: flex;
    justify-content: center;

}

.home-main-about-section-two-img
{
    height:40vh;
    width: 100%;
    margin: 2vh;

}


.main-home-help-section
{
    height: fit-content;
}

.main-home-help-section-modren-way-heading
{
    font-size: 2rem;
    text-align: center;
}
.main-home-help-section-one
{
    padding:2vh;
}

.main-home-help-section-modren-way-text
{
    font-size: 1rem;
    text-align: justify;
    line-height: 1.3rem;
    letter-spacing: 0.03rem;
    word-spacing: 0.14rem;
    font-family: 'Barlow', sans-serif;
    font-size: 1rem;
    color: rgb(0, 0, 0);
    font-weight: 500;
}




.main-about-us-container-facts-container-home {
    height: 30vh;
    width: 100vw;
    background-image: url(./images/section.jpg);
  }


.home-main-service-section
{
    height: 380vh;
    margin-top: 10vh;
}



.home-main-service-section-container-one
{
    flex-direction: column;
    padding: 2vh;
    align-items: center;
    margin: 0px;
    height: fit-content;

}

.home-main-service-section-container-two
{
    flex-direction: column;
    padding: 2vh;
    align-items: center;
    margin: 0px;
    position: relative;
    top: -4vh;
}

.home-main-service-section-heading
{
    padding: 2vh;
}

.home-main-service-section-card
{
    margin-bottom: 5vh;
}


.home-main-simple-way
{
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    height: 80vh;
}
.home-main-simple-way-section-one
{
    height: 100%;
    width: 100%;
    padding: 0px;
    

}

.home-main-simple-way-section-two
{
    height: 50vh;
    position: relative;
    top: -10vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-main-simple-way-section-one-text
{
    font-size: 1rem;
    text-align: justify;
    line-height: 1.3rem;
    letter-spacing: 0.03rem;
    word-spacing: 0.14rem;
    font-family: 'Barlow', sans-serif;
    font-size: 1rem;
    color: rgb(0, 0, 0);
    font-weight: 500;
    padding: 2vh;
}

.home-main-simple-way-section-one-heading
{
    padding: 0px;
    font-size: 2.5rem;
    margin-bottom: 10vh;
    text-align: center;
    margin-bottom: 5vh !important;
}
.section-experience-home-one
{
    height: fit-content;
width: 100%;
padding: 0px;
font-size: 2.3rem;
margin-bottom: 10vh;
text-align: center;
margin-bottom: 5vh !important;
padding: 2vh;

}


.home-how-it-works-container
{
    height: 25vh;
}

.home-how-it-works-heading
{
    font-size: 2.5rem;
    margin-bottom: 10vh;
    text-align: center;
    margin: 0px;
}

.home-how-it-works-detail-container
{
    padding: 2vh;
    flex-direction: column;
    width: 100%;
    position: relative;
    top: 30vh;
}


.home-how-it-works
{
    height: 130vh;
    width: 100vw;
}

.home-how-it-works-detail-container-section
{
    width: 100%;
    padding: 2vh;
    padding-bottom: 3vh;
}
.home-how-it-works-detail-container-section2
{
    width: 100%;
    padding: 2vh;
    padding-bottom: 3vh;

}

.home-how-it-works-detail-container-section-heading
{
    text-align: center;
}

.home-how-it-works-detail-container-section-heading-para
{
    margin: 0px;
    font-size: 0.8rem;
    padding-top: 1.3vh;
    word-spacing: 0.01rem;
    line-height: 1rem;
    width: 100%;
    font-family: 'Barlow', sans-serif;
    text-align: justify;
    font-weight: 500;

}

.home-main-training-section-one
{
    width: 100vw;
}

.home-main-about-section-one-heading-text
{
    padding: 0px;
}

.home-main-about-section-one-text
{
    padding: 2vh;

}

.home-main-training-section-two
{
    height: 40vh;
    width: 100vw;
   padding: 2vh;
}

.home-main-traning-section-two-img
{
    height:100%;
    width: 100%;
    background-size: 80vh;
}
.section-experience-home-two
{
    width: 100%;
    padding: 0px;
    
}



.section-experience-home
{
    height: fit-content;
    width: 100vw;
    display: flex;
    padding: 0px;
}

.main-container-wrapper-exp
{
    width: 100%;
    height: 80vh;
    align-items: center;
}
.main-container-wrapper-exp-one
{
    width: 100%;
    height: 40vh;
    align-items: center;
}
.div-home-main-container-part-one
{
    padding-top: 5vh;
}
 }