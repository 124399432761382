.div-main-container-form-submisstion
{
  height: 100vh;
  width: 100vw;
  display: flex;
}

.div-main-container-form-submisstion-img
{
  height: 100vh;
  width: 35vw;
  background-color: #2667fa;
  background-image: url(./images/flowbase.svg);
  background-size: cover;
}

.div-main-container-form-submisstion-text
{
  height: 100vh;
  width: 65vw;
  padding: 17vh 15vh 0vh 20vh;
}


.main-register-form-container-section-two-heading
{
font-size: 2.5rem;
margin: 0px;
margin-bottom: 5vh;
}

.main-input-container-section
{
  height: 17vh;
  width: 100%;
  display: flex;
}

.input-section-div
{
  height: 100%;
  width: 50%;
}

.register-label
{
    color: #02044a;
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin: 1vh;
    width: fit-content;
}


.register-name 
{
  position: relative;
  width: 80%;
  min-height: 58px;
  margin-bottom: 0px;
  padding-left: 16px;
  border: 1px solid #f9f9fb;
  border-radius: 8px;
  background-color: #f9f9fb;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-size: 16px;
  cursor: pointer;
}

.input-section-div-error
{
  margin: 1vh;
  color: red;
}

.div-multi-select-option
{
  height: 20vh;
  width: 50%;
  background-color: black;
}

.register-label-option {
  margin-top: 2vh;
  overflow: hidden;
  position: relative;
  display: inline-block;
  height: 6vh;
  font-size: 1rem;
  color: #02044a;
  font-weight: 500;
  width: 20vw;
  background-color: black;
  display: flex;
  background-color: #f5f5f5;
  align-items: center;
  justify-content: space-between;
  padding: 0vh 2.2vh 0vh 2.2vh;
  border-radius: 1vh;
  margin-bottom: 1.5vh;
}


.input-radio-wrapper {
  height: fit-content;
  width: 20vw;
  position: absolute;
  border-radius: 1vh;
  background-color: #f5f5f5;
  
}

.input-radio-wrapper-one {
  display: none;
  content: "";
}

.input-span {
  display: block;
  height: 5.5vh;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
  width: 100%;
  color: #02044a;
}

.input-span:hover {
  color: white;
  transition: 0.3s ease all;
}
.option1{
  border-top-left-radius: 1vh;
  border-top-right-radius: 1vh;
}

.optioncourse:hover {
  background-color: black;
}
.option5 {
  border-bottom-left-radius: 1vh;
  border-bottom-right-radius: 1vh;
}
.checked-in {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 2vh;
  overflow: hidden;
}
input[type="checkbox"]  {
  display: none;
}



input[type=checkbox]:checked + label
{
  background-color: #000000;
  color: white;

}





.main-register-submit-container {
  top: 15vh;
  position: relative;
  left: 55vh;
  width: 10vw;
}

.main-register-submit {
  height: 7vh;
  width: 10vw;
  overflow: hidden;
  background-color: #0057ff;
  color: white;
  border: 0px;
  font-size: 1.3rem;
  border-radius: 1vh;
  position: relative;
  float: right;
}

.opacitychange
{
  opacity: 0.5;
}

.main-register-captcha
{
  float: right;
}
.main-register-captcha div
{
  position: relative;
  right: 0px;
}


.wrapper-submit
{
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-family: 'Barlow', sans-serif !important;
  margin-top: 5vh;
}



@media (max-width:480px) 
 {

.div-main-container-form-submisstion
{
  height: fit-content;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.div-main-container-form-submisstion-img
{
  height: 40vh;
  width: 100vw;
  padding: 0vh;
  background-size: cover;
}

.div-main-container-form-submisstion-text
{
  height: 100vh;
  width: 100vw;
  padding: 0vh;
}


.main-input-container-section
{
  height: fit-content;
  flex-direction: column;
}
.input-section-div
{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.register-label
{
    color: #02044a;
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin: 1vh;
    width: 100%;
    padding-left: 6vh;
    
}
.wrapper-select-for-course
{
  display: flex;
  justify-content: center;
}
.register-label-option {
  margin: 0vh;
  position: static;
  width: 80vw;
  padding: 2vh;
  border-radius: 1vh;
  margin-top: 5vh;
  background-color: #f5f5f5;
  
}

.input-radio-wrapper-for-flex
{
  width: 100vw;
  display: flex;
  justify-content: center;
}
.input-radio-wrapper {
  height: fit-content;
  width: 80vw;
  position: static;
  border-radius: 1vh;
  background-color: #f5f5f5;
  
}
.main-register-captcha
{
  width: 100%;
  display: flex;
  justify-content: center;


}
.main-register-captcha div
{
  position: relative;
  right: 0px;
}

.main-register-form-container-section-two-heading
{
font-size: 1.8rem;
margin: 0px;
height: 20vh;
margin-bottom: 0vh;
display: flex;
justify-content: center;
align-items: center;
}

.wrapper-submit
{
  align-items: center;
}


.main-register-submit {
  width: 30vw;
  float: none;
  margin-bottom: 10vh;
}


 }