.main-our-training-landingpage {
  height: 80vh;
  width: 100vw;
  background: rgb(12,51,139);
  background: linear-gradient(102deg, rgba(12,51,139,1) 0%, rgba(10,91,185,1) 100%);
}

.main-our-training-landingpage-text {
  margin: 0px;
  padding-top: 40vh;
  font-size: 3rem;
  padding-left: 20vh;
  color: white;
  height: 55vh;
  display: flex;
  padding-left: 7vw;
  flex-direction: column;
  justify-content: flex-end;
  text-shadow: #000000 3px 3px;
  text-align: left;
  width: 100%;
  font-size: 8rem;
  line-height: 8rem;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
}

.main-our-training-landingpage-three-section {
  height: 110vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 15vh 0vh 0vh 0vh;
}

.main-our-training-landingpage-section-img {
  height: 100%;
  width: 50%;
  background-image: url(./images/coursemodel.webp);
  background-size: 120vh;
  background-position: center;
  background-repeat: no-repeat;
}
.main-our-training-landingpage-section-text {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 5vh 0vh 5vh 10vh;
}

.main-our-training-landingpage-section-head {
  font-size: 1rem;
  font-weight: 500;
  color: #1454d8;
  height: 8vh;
  display: flex;
  align-items: flex-end;
  font-weight: 600;
}

.main-our-training-landingpage-section-heading {
  font-size: 3rem;
  font-weight: 500;
  color: #000000;
}

.main-our-training-landingpage-section-card {
  height: 17vh;
  width: 90%;
  display: flex;
}

.main-our-training-landingpage-section-card-icon {
  height: 100%;
  width: 20%;
  background-position: center;
  background-size: 60px;
  background-repeat: no-repeat;
}

.main-course-icon-one {
  background-image: url(./images/online-learning.png);
}

.main-course-icon-two {
  background-image: url(./images/certificate.png);
}

.main-course-icon-three {
  background-image: url(./images/expert.png);
}

.main-our-training-landingpage-section-card-text {
  display: flex;
  height: 100%;
  width: 80%;
  flex-direction: column;
  padding: 1vh 8vh 1vh 3vh;
  justify-content: space-evenly;
}

.main-our-training-landingpage-section-card-text span:nth-child(1) {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1454d8;
}
.main-our-training-landingpage-section-card-text span:nth-child(2) {
  font-size: 0.93rem;
  color: rgb(64, 64, 64);
}

.home-main-our-courses-container {
  height: 350vh;
  width: 100vw;
  padding: 7% 5% 7% 5%;
  display: flex;
  flex-direction: column;
  background-color: white !important;
}

.home-main-our-courses-title {
  height: 5%;
  width: 100%;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: 500;
  font-family: "Barlow", sans-serif;
}

.home-main-our-courses-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.home-main-our-courses-section-part {
  border-radius: 2vh;
  box-sizing: content-box;
  width: 70%;
  height: 50vh;
  display: flex;
  display: flex;
  justify-content: center;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}
.home-our-courses-section-img {
  width: 50%;
  height: 100%;
  border-top-left-radius: 2vh;
  border-bottom-left-radius: 2vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.part-ceh {
  background-image: url(./images/hacking.jpg);
}

.part-web {
  background-image: url(./images/newweb.jpeg);
}

.part-web {
  background-image: url(./images/newweb.jpeg);
}
.part-mobile {
  background-image: url(./images/mobile.webp);
}

.part-network {
  background-image: url(./images/courseinfra.jpg);
}

.part-cloud {
  background-image: url(./images/coursecloud.jpg);
}

.home-main-our-courses-section-part-wrapper {
  width: 50%;
  height: 100%;
  padding: 5% 0 0 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 3vh;
}

.home-main-our-courses-section-part-wrapper-left {
  width: 50%;
  height: 100%;
  padding: 5% 0 0 12%;
}

.home-our-courses-section-wrapper-title {
  height: fit-content;
  padding-bottom: 1vh;
  word-spacing: 0.12rem;
  font-size: 1.7rem;
  letter-spacing: -0.05em;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
}

.home-our-courses-section-wrapper-by {
  font-size: 0.9rem;
  color: #000000;
  padding-bottom: 2vh;
  font-family: "Barlow", sans-serif;
}

.home-service-area-link {
  display: flex;
  justify-content: flex-end;
  padding-right: 20vh;
}

.home-our-courses-section-wrapper-description {
  height: 20vh;
  padding-right: 10%;
  font-family: "Barlow", sans-serif;
  font-size: 0.93rem;
  word-spacing: 0.12rem;
  letter-spacing: 0.03rem;
  line-height: 1.3rem;
}

.home-our-courses-section-wrapper-description ul {
  margin-left: 1vh;
  font-family: "Barlow", sans-serif;
  font-size: 0.8rem;
  word-spacing: 0.09rem;
  letter-spacing: 0.01rem;
  line-height: 1rem;
  text-align: justify;
}

.home-our-courses-section-wrapper-description li {
  margin-bottom: 1vh;
  font-size: 1rem;
  line-height: 1.8rem;
  letter-spacing: 0.03rem;
  word-spacing: 0.1rem;
}



.card-one-section-2-button-inner
{
    padding: 1% 2% 1% 2%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    border: 0px;
    height: 6vh;
    width: 9vw;
    font-size: 1rem;
    background-color: #000000;
    color: white;
    font-weight: 800;
    font-family: 'Barlow', sans-serif;
    transition: 0.3s ease-in-out;

}

.card-one-section-2-button-inner:hover
{
    transition: 0.3s ease-in-out;
    transform: scale(1.05);

}

.card-one-section-2-button-inner >.CgArrowRight-ourtraining
{
   font-size: 1.2rem;
}














@media (max-width:480px) 
 {

.home-main-our-courses-container {
  height: 400vh;
  width: 100vw;
  padding: 7% 5% 7% 5%;
  display: flex;
  flex-direction: column;
  background-color: white !important;
}

  .main-our-training-landingpage-three-section {
    height: fit-content;
    flex-direction: column;
    padding:  2vh;

  }


.main-our-training-landingpage-section-img {
  height: 30vh;
  width: 100%;
  background-size: cover;
}

.main-our-training-landingpage-section-text {
  height: 100vh;
  width: 100%;
  padding: 0vh;
}

.main-our-training-landingpage-section-heading {
  font-size: 1.4rem;
  font-weight: 500;
  color: #000000;
  text-align: center;
  margin-top: 8vh;
}


.main-our-training-landingpage-section-card-text {
  width: 100%;
  padding: 0vh 0vh 0vh 3vh;
}

.main-our-training-landingpage-section-card-text span:nth-child(1) {
  font-size: 1.3rem;
  font-weight: 600;
  color: #1454d8;
  width: 100%;
}
.main-our-training-landingpage-section-card-text span:nth-child(2) {
  font-family: "Barlow", sans-serif;
  font-size: 0.8rem;
  word-spacing: 0.09rem;
  letter-spacing: 0.01rem;
  line-height: 1rem;
  text-align: justify;
}


.main-our-training-landingpage-section-card {
  height: 17vh;
  width: 100%;
  display: flex;
}

.main-our-training-landingpage-section-card-icon {
  width: 25%;
}

.home-main-our-courses-title {
  height: 5vh;
  font-size: 2rem;
  text-align: center;
}

.home-main-our-courses-section {
  height: 400vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.home-main-our-courses-section-part {
  border-radius: 2vh;
  box-sizing: content-box;
  width: 95%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}
.home-main-our-courses-section-part-wrapper
{
  width: 100% !important;
  height: fit-content;
  padding: 2vh;
}


.home-our-courses-section-img {
  width: 100%;
  height: 30vh;
  border-top-left-radius: 2vh;
  border-bottom-left-radius: 0vh;
  border-top-right-radius: 2vh;
}
.home-service-area-link {
  display: flex;
  justify-content: center;
  padding-right: 0vh;
}

.home-our-courses-section-wrapper-description {
  height: fit-content;
  padding: 2vh;
}
.card-one-section-2-button-inner
{
    width: 30vw;
}


.home-our-courses-section-wrapper-description li {
  margin-bottom: 1vh;
  font-size: 0.8rem;
  line-height: 1.1rem;
  letter-spacing: 0.009rem;
  word-spacing: 0.05rem;
  text-align: justify;
}

 }