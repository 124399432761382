.testimonial-text
{
    border-bottom-left-radius: 10vh;
    border-top-right-radius: 10vh;
    height: 100%;
    width: 100%;
    padding: 10vh 7vh 0vh 7vh;
    display: flex;
    font-size: 1.2rem;
    justify-content: center;
    align-items: center;
    color: black;

}

.student-testimonial
{
    color: rgb(255, 255, 255);
    background: rgb(12,51,139);
    background: linear-gradient(102deg, rgba(12,51,139,1) 0%, rgba(10,91,185,1) 100%);

}


.testimonial-heading
{
    position: relative;
    z-index: 2;
    left: -10vh;
    height: 7vh;
    width: 13vw;
    margin: 0px;
    display: flex;
    align-self: flex-end;
    margin-bottom: 5vh;
    justify-content: center;
    align-items: flex-end;
    color: rgb(0, 0, 0);
}

.student-testimonial-heading
{
    position: relative;
    z-index: 2;
    left: -10vh;
    height: 10vh;
    width: 13vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: rgb(0, 0, 0);
}

.main-react-testimonial-heading
{
    height: 10vh;
    width: 100vw;
    font-size: 3rem;
    color: white;
    padding: 10vh 0vh 0vh 15vh;
}
.main-react-testimonial-heading-student
{
    height: 10vh;
    width: 100vw;
    font-size: 3rem;
    color: rgb(0, 0, 0);
    padding: 10vh 0vh 0vh 15vh;
}

.div-testimonial-arrow
{
    height: 5vh;
    width: 5vh;
    border-radius: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    background-color: rgb(255, 255, 255);
    color: black;
    transition: 0.3s ease-in-out;
    margin: 5vh;
}

.student-testimonial-arrow
{
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
}

.div-testimonial-arrow:hover
{
    transition: 0.3s ease-in-out;
    font-size: 2.1rem;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    transform: scale(1.1);
}

.outer-container-div-react-testimonial
{
    background: rgb(12,51,139);
    background: linear-gradient(102deg, rgba(12,51,139,1) 0%, rgba(10,91,185,1) 100%);
}

.div-react-testimonial
{
    height: 70vh;
    width: 100vw;
    padding: 0vh 10vh 0vh 10vh;
    display: flex;
    justify-content:center;
    align-items: center;

}

.react-testimonial
{
    display: none;
}



.main-testimonial-view-container
{
    height: 60vh;
    display: flex;
    align-items: center;
}


.div-testimonial-container-flex
{
    display: flex;
    height: 40vh;
    width: 50vw;
    background-color: white;
    animation: fadeinandout  0.8s ease-in-out, inyappear 0.5s ease-in-out;
    animation-fill-mode: forwards;
    border-bottom-left-radius: 10vh;
    border-top-right-radius: 10vh;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 10vh;

}

@keyframes inyappear {
    0%
    {
        transform: translateY(10%);
    }
    100%
    {
        transform: translateY(0%);
    }
    
}

@keyframes fadeinandout 
{
    0%
    {
        opacity: 0;
    }
    100%
    {
        opacity: 1;
    }
}

.div-testimonial-slider
{
    height: 10vh;
    width: 100vw;
    top: -10vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

}

.div-testimonial-slider-dot
{
    height: 1.2vh;
    width: 1.2vh;
    border-radius: 2vh;
    margin: 1vh 1vh 1vh 1vh;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
}
.div-testimonial-slider-dot-current
{
    height: 1.4vh;
    width: 1.4vh;
    border-radius: 2vh;
    margin: 0vh 1vh 0vh 1vh;
    transform: scale(1.2);
    background-color: rgb(255, 255, 255);
    cursor: pointer;

}

.student-dot-current
{
    background-color: rgb(0, 0, 0);
}
.student-dot
{
    background-color: rgb(96, 96, 96);
}

.color-student
{
    color: white;
}






@media (max-width:480px) 
 {


    .div-react-testimonial
    {
        width: 100vw;
        padding: 0vh;
        height: 45vh;
    }
    
    .div-testimonial-arrow
{
    display: none;
    width: 0px;
}

.main-testimonial-view-container
{
    height: fit-content;

}


.div-testimonial-slider
{
    height: 5vh;
    width: 100vw;
    top: -6vh;
    align-items: flex-end;

}
.div-testimonial-container-flex
{
    height: 30vh;
    width: 90vw;
    padding-top: 3vh;
    padding-bottom: 3vh;
    border-bottom-left-radius: 5vh;
    border-top-right-radius: 5vh;
}

.testimonial-text
{
    border-bottom-left-radius: 5vh;
    border-top-right-radius: 5vh;
    padding: 3vh;
    font-size: 0.8rem;
    text-align: justify;
    line-height: 1.1rem;
    letter-spacing: 0.03rem;
    word-spacing: 0.1rem;
    font-weight: 300;
    padding: 2vh 3vh 3vh 3vh;

}

.main-react-testimonial-heading
{

    font-size: 2rem;
    padding: 0vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.testimonial-heading
{
    z-index: 2;
    left: 0vh;
    padding-left: 5vh;
    height: 7vh;
    width: 100vw;
    margin: 0px;
    display: flex;
    align-self: flex-end;
    margin-bottom: 0vh;
    justify-content: center;
    align-items: flex-end;
    color: rgb(0, 0, 0);
}


.student-testimonial-heading
{
    z-index: 2;
    left: 0vh;
    padding-left: 5vh;
    height: 7vh;
    width: 100vw;
    margin: 0px;
    display: flex;
    align-self: flex-end;
    margin-bottom: 0vh;
    justify-content: center;
    align-items: flex-end;
    color: rgb(255, 255, 255);
}

.main-react-testimonial-heading-student
{
    height: 10vh;
    width: 100vw;
    font-size: 3rem;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: flex-end;

    padding: 0vh;
}

 }