@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap');
.main-footer-container
{
    height: 90vh;
    width: 100vw;
}

.main-footer-container-section-one
{
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    height: 30%;
    background: rgb(12,51,139);
    background: linear-gradient(102deg, rgba(12,51,139,1) 0%, rgba(10,91,185,1) 100%);
    padding: 2vw 10vw 2vw 10vw;
    background-size: cover;
    display: flex;
}


.main-footer-container-section-one-part1
{
    width: 60%;
    height: 100%;
    color: white;
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;

}
.MdVerified
{
    width: 5vw;
    padding-left: 1vw;
}




.main-footer-container-section-two
{
    height: 95%;
    background: linear-gradient(to right, #0c0f12 0%, #16171C 100%);
    
}

.inner-footer-section-space
{
    height: 10%;
    width: 100%;
}

.inner-footer-section-one
{
    height: 65%;
    width: 100%;
    padding: 0vh 10vw 0vh 10vw;
    display: flex;
}

.inner-footer-section-two
{
    height: 25%;
    width: 100%;
}

.inner-footer-container-one
{
    width: 55%;
    height: 100%;
}

.inner-footer-container-one-logo
{
    height: 15%;
    width: 25%;
    background-image: url(./images/logo.webp);
    image-rendering: -webkit-optimize-contrast;
    background-size: contain;
    background-repeat: no-repeat;
}


.inner-footer-container-one-contact
{
    height: 70%;
    width: 100%;
    padding: 5vh 0 0 0;
}

.inner-footer-container-one-text-wrapper
{
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fawhatsapp
{
    font-size: 2rem;
    color: white;
}




.inner-footer-container-one-text
{
    height: 4vh;
    width: 100%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    font-family: 'Barlow', sans-serif;
    font-weight: 200;
}


.inner-footer-container-two
{
    width: 40%;
    height: 100%;
    display: flex;
}

.inner-footer-container-two-part-one
{
    height: 100%;
    width: 50%;
}
.inner-footer-container-two-part-two
{
    height: 100%;
    width: 50%;
}

.footer-container-two-part-one-header
{
    height: 20%;
    width: 100%;
    font-size: 1.3rem;
    color: white;
    display: flex;
    align-items: center;
    padding-left: 3vw;
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
}

.inner-footer-container-two-part-one-space
{
    height: 10%;
    width: 100%;
}

.footer-navlink
{
    display: flex;
    flex-direction: column;
}

.footer-navlink-links
{
    height: 2.5vw;
    width: 10vw;
    display: flex;
    color: #8a8a8a;
    text-decoration: none;
    padding-left: 3vw;
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
}



.footer-navlink-links:hover
{ 
    color: white;
    transform: scale(1.1);
    transition: 0.2s;
}

.footer-services-wrapper
{
    height: 50%;
    width: 100%;
    padding-left: 3vw;
    color: #8a8a8a;
    font-family: 'Barlow', sans-serif;
    font-weight: 300;
}

.footer-services-wrapper-text
{
    height: 2.5vw;
    font-weight: 400;
}

.inner-footer-section-two-line
{
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
}
.footer-section-two-line-segment
{
    height: 1px;
    width: 80%;
    background-color: white;
}

.inner-footer-section-two-media-and-text
{
    height: 25%;
    width: 100%;
    padding-left: 10vw;
    padding-right: 10vw;
    display: flex;
}

.inner-footer-section-two-companyname
{
    height: 100%;
    width: 33.3%; 
    color: white;
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.inner-footer-section-two-policy
{
    height: 100%;
    width: 33.3%;
    color: white;
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 0 5vw 0 5vw;
    justify-content: space-evenly;
}
.inner-footer-section-two-media-icons
{
    height: 100%;
    width: 33.3%;
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
}

.media-icons-footer
{
    width: 4vw;
}

.media-icons-footer:hover
{
    transition: .3s ease-in-out;
    transform: rotate(10deg) scale(1.1);
    
}


.footer-container-one-text-wrapper
{
    width: 100%;
    padding-left: 2%;
    color: white;
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
}

.inner-footer-container-one-contact-section
{
    padding-left: 5vw;
    height: 25%;
    width: 100%;
    display: flex;
    align-items: center;
}

.home-section-contact-icon
{
    color: white;
    font-size: 1.5rem;
}

.home-section-contact-text
{
    width: 100%;
    padding-left: 1vw;
    color: white;
    font-size: 1.1rem;
    font-weight: 200;
    font-family: 'Barlow', sans-serif;
    word-spacing: 0.1rem;
    letter-spacing: 0.05rem;


}



.color-icon
{
    color: white;
    font-size: 4vh;
}

.home-quotes-section
{

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1vh 0vh 10vh 0vh;
    height: 50vh;
    width: 100vw;
    font-weight: 600;
    justify-content: space-evenly;
    background-size: cover;
    background-repeat: no-repeat;
}
.inner-footer-section-two-policy-one
{
    color: white;
    text-decoration: none;
}






























@media (max-width:480px) 
 {

    .inner-footer-section-space
{
    height: 5%;
    width: 100%;
}
    .main-footer-container
    {
        height: 140vh;
        width: 100vw;
    }

.inner-footer-section-one
{
    height: fit-content;
    width: 100%;
    flex-direction: column;
    margin-bottom: 4vh;
    padding: 0vh 5vw 0vh 5vw;
}
.home-section-contact-text
{
    width: 80%;
    padding: 0px;
    font-size: 1rem;
    font-weight: 500;
}

.main-footer-container-section-two
{
    height: 100%;
    background: linear-gradient(to right, #0c0f12 0%, #16171C 100%);
    
}

.footer-services-wrapper
{
    height: 50%;
    width: 100%;
    padding-left: 3vw;
    color: #8a8a8a;
    font-family: 'Barlow', sans-serif;
    font-weight: 300;
}
.inner-footer-container-two-part-one
{
    height: 100%;
    width: 100%;
    padding-left: 2vh;
}
.inner-footer-container-two
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.footer-navlink-links
{
    height: fit-content;
    width: 100%;
    line-height: 4vh;
    padding-left: 5vw;
}
.footer-navlink
{
    display: flex;
    margin-bottom: 3vh;
}



.footer-services-wrapper-text
{
    line-height: 4vh;
    height: fit-content;
    font-weight: 400;
}
.inner-footer-container-one-contact
{
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
    width: 100%;
    padding: 0px;
    margin-bottom: 3vh;
}

.inner-footer-container-one-contact-section
{
    width: 100%;
    padding-left: 0vw;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 2vh 0vh 2vh 0vh;
}

.home-section-contact-icon
{
    color: white;
    width: 20%;
    font-size: 1.7rem;
}
.inner-footer-container-one
{
    width: 100%;
    height: 100%;
}
.inner-footer-section-two
{
    height: fit-content;
    width: 100%;
}
.inner-footer-section-two-media-and-text
{
    margin-top: 5vh;
    height: 25%;
    width: 100%;
    padding-left: 10vw;
    padding-right: 10vw;
    display: flex;
    flex-direction: column;
}

.inner-footer-section-two-companyname
{
    justify-content: center;
    width: 100%; 
}

.inner-footer-section-two-policy
{
    margin-top: 2vh;
    height: fit-content;
    width: 100%;
    justify-content: space-evenly;
}

.inner-footer-section-two-media-icons
{
    height: 10vh;
    width:100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    font-size: 10rem;
    padding: 0vh 5vh 0vh 5vh;
}

.color-icon
{
    color: white;
}

.media-icons-footer
{
    transform: scale(2);
}

.footer-container-two-part-one-header
{
    height: 8vh;
    width: 100%;
    font-size: 1.7rem;
}

.footer-services-wrapper
{
    height: 50%;
    width: 100%;
    padding-left: 5vw;
    color: #8a8a8a;
    font-family: 'Barlow', sans-serif;
    font-weight: 300;
}


.inner-footer-container-one-logo
{
    height: 8vh;
    width: 100%;
    margin-left: 2vh;
    margin-bottom: 2vh;
}

.inner-footer-container-one
{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: 100%;
}



.main-footer-container-section-one-part1
{
    width: 100%;
    height: 100%;
    color: white;

    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: 500;
}

.main-footer-container-section-one-part1 span
{
    font-size: 1.5rem;
    width: 80%;
}
.MdVerified
{
    width: 20%;
    padding-left: 0px;
}
.main-footer-container-section-one
{
    height: 20%;
}


.home-quotes-section-text
{
    height: fit-content;
    font-size: 1.3rem;
}
.home-quotes-section
{
    justify-content: center;
    padding: 1vh 0vh 2vh 0vh;
}
 }