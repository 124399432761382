
.main-container-service
{
    height: 80vh;
    width: 100vw;
    background: rgb(12,51,139);
    background: linear-gradient(102deg, rgba(12,51,139,1) 0%, rgba(10,91,185,1) 100%);
}

.main-cotnainer-heading-two {
  height: 30vh;
  width: 100vw;
  color: #075bb9;
  font-size: 2.4rem;
  font-weight: 600;
  font-family: "Barlow", sans-serif !important;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0px;
  padding-bottom: 7vh;
}

.main-container-service-section {
  height: fit-content;
  width: 100vw;
  align-items: center;
}

.main-container-service-section-info, .main-container-service-section-info-2 {
  height: 120vh;
  width: 100%;
  padding-top: 3vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-container-service-section-info-img {
  height: 100%;
  width: 35%;
  background: url(./images/Ourservices2.jpg);
  background-size: cover;
  background-position: center;
}

.main-container-service-section-info-img:hover {
  background: linear-gradient(rgba(0, 33, 98, 0.3), rgba(0, 41, 123, 0.5)),
    url(./images/Ourservices2.jpg);
  background-size: cover;
  background-position: center;
}

.main-container-service-section-info-text {
  height: 80%;
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.main-container-service-section-info-text-wrapper {
  height: 40%;
  width: 100%;
}

.main-container-service-section-info-text-heading {
  padding-right: 10vh;
  height: fit-content;
  width: 100%;
  font-family: "Barlow", sans-serif;
  font-size: 2.7rem;
  padding-left: 10vh;
  transition: 0.3s linear;
  color: #075bb9;
}

.main-container-service-section-info-text-info {
  height: 70%;
  width: 100%;
  padding: 2% 10% 0% 10%;
  line-height: 2rem;
  letter-spacing: 0.11rem;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  font-size: 1.05rem;
}

.main-container-service-section-info-img-2 {
  height: 100%;
  width: 35%;
  background: url(./images/Ourservices1.jpeg);
  background-size: cover;
  background-position: center;
}

.main-container-service-section-info-text-heading-line {
  height: 3px;
  width: 00%;
  background-color: #075bb9;
  margin-top: 1vh;
  margin-left: 10vh;
  border-radius: 10vh;
  transition: 0.7s cubic-bezier(0.16, 0.37, 0.64, 0.18);
}

.main-container-service-section-info-text-wrapper:hover
  .main-container-service-section-info-text-heading-line {
  transition: 0.7s cubic-bezier(0.16, 0.37, 0.64, 0.18);
  width: 80%;
}

.main-container-service-section-info-text-wrapper:hover .for-pentest {
  transition: 0.7s cubic-bezier(0.16, 0.37, 0.64, 0.18);
  width: 81%;
}

.main-container-service-section-info-text-wrapper:hover .for-web {
  transition: 0.7s cubic-bezier(0.16, 0.37, 0.64, 0.18);
  width: 74%;
}
.main-container-service-section-info-text-wrapper:hover .for-mob {
  transition: 0.7s cubic-bezier(0.16, 0.37, 0.64, 0.18);
  width: 78.5%;
}

.main-container-service-section-info-text-wrapper:hover .for-infra {
  transition: 0.7s cubic-bezier(0.16, 0.37, 0.64, 0.18);
  width: 72.5%;
}

.main-container-service-section-info-text-wrapper:hover .for-cloud {
  transition: 0.7s cubic-bezier(0.16, 0.37, 0.64, 0.18);
  width: 51%;
}

.main-container-service-section-info-text-wrapper:hover .for-api {
  transition: 0.7s cubic-bezier(0.16, 0.37, 0.64, 0.18);
  width: 52%;
}

.main-container-service-section-info-img-2:hover {
  background: linear-gradient(rgba(0, 33, 98, 0.3), rgba(0, 41, 123, 0.5)),
    url(./images/Ourservices1.jpeg);
  background-size: cover;
  background-position: center;
}

.main-container-service-section-why {
  height: fit-content;
  width: 100vw;
  background-color: black;
}

.main-container-service-section-why-heading {
  text-align: center;
  height: 30vh;
  width: 100%;
  font-family: "Barlow", sans-serif;
  font-size: 5.3rem;
  font-weight: 600;
  -webkit-text-stroke: 0.2vh rgba(172, 172, 172, 0.791);
  transition: 0.3s linear;
  color: white;
}

.main-container-service-section-why-section-one-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 75%;
  color: white;
  font-size: 1.4rem;
  line-height: 1.7rem;
  letter-spacing: 0.13rem;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  padding: 5vh;
}

.main-container-service-section-why-section-one-icon-part-1 {
  width: 100%;
  height: 50%;
}
.container-service-section-why-icon {
  height: 40vh;
  width: 100%;

  display: flex;
}
.container-service-section-why-icon-part {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1.4rem;
  line-height: 1.7rem;
  letter-spacing: 0.1rem;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #7da8ff;
}
.container-service-section-why-icon-part2 {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  line-height: 1.7rem;
  letter-spacing: 0.1rem;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #7da8ff;
}

.container-service-section-why-icon-part-place1 {
  height: 45%;
  width: 40%;
  background-size: 10vh;
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 3vh;
}

.section-why-icon-part-place1 {
  background-image: url(./images/starsss.png);
}
.section-why-icon-part-place1-left {
  background-image: url(./images/5starrating.png);
}
.container-service-section-why-icon-part-place2 {
  height: 45%;
  width: 100%;
  background-size: 10vh;
  background-position: top;
  background-repeat: no-repeat;
  background-image: url(./images/turnaround.png);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 3vh;
}
.section-why-icon-part-place2 {
  background-image: url(./images/supportpng.png);
}

.container-service-section-why-icon-text {
  font-size: 1.3rem;
  padding: 0vh 20vh 10vh 20vh;
  color: #ffffff;
  line-height: 1.7rem;
  letter-spacing: 0.1rem;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.container-service-section-why-icon-text li {
  padding: 3vh 0vh 3vh 0vh;
}

.main-container-servicepage-icon-and-heading {
  height: 40vh;
  width: 100vw;
  display: flex;
  justify-content: space-around;
  padding: 4vh 5vh 20vh 5vh;
}




.main-container-heading
{
    margin: 0px;
    padding-top: 40vh;
    font-size: 3rem;
    padding-left: 20vh;
    color: white;
    height: 55vh;
    display: flex;
    padding-left: 7vw;
    flex-direction: column;
    justify-content: flex-end;
    text-shadow: #000000 3px 3px ;
    text-align: left;
    width: 100%;
    font-size: 8rem;
    line-height: 8rem;
    font-weight: 700;
    font-family: 'Barlow', sans-serif;
}





@media (max-width:480px) 
 {



.main-container-service
{
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    background: rgb(12,51,139);
    background: linear-gradient(102deg, rgba(12,51,139,1) 0%, rgba(10,91,185,1) 100%);
}


  .main-container-heading
  {
      margin: 0px;
      padding: 0vh;
      color: rgb(255, 255, 255);
      height: 30vh;
      display: flex;
      justify-content: center;
      font-size: 3rem;
      align-items: center;
  }

  .main-container-service-section-info {
    height:  fit-content;
    width: 100%;
    padding-top: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2vh;
  }

  .main-container-service-section-info-2
  {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 5vh !important;
  }
  .main-container-service-section-info-text-heading-line
  {
    display: none;
  }


.main-container-service-section-info-img {
  height: 30vh;
  width: 90vw;
}

.main-container-service-section-info-img-2 {
  height: 30vh;
  width: 90vw;
}

.main-container-service-section-info-text-wrapper {
  height: 40%;
  width: 100%;
}

.main-container-service-section-info-text {
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  
}
.main-container-service-section-info-text-heading {
  margin-top: 5vh;
  padding-right: 0vh;
  height: fit-content;
  width: 100%;
  text-align: center;
  font-family: "Barlow", sans-serif;
  font-size: 1.7rem;
  padding-left: 0vh;
  transition: 0.3s linear;
  color: #075bb9;
}



.main-container-service-section-info-text-info { 
    padding: 2% 10% 0% 10%;
    text-align: justify;
    line-height: 1.3rem;
    letter-spacing: 0.03rem;
    word-spacing: 0.14rem;
    font-family: 'Barlow', sans-serif;
    font-size: 0.9rem;
    color: rgb(0, 0, 0);
    font-weight: 500;
}


.main-cotnainer-heading-two {
text-align: center;
}

 }