

.submit-popup-blue
{
    position: fixed;
    top: 0;
    opacity: 1;
    height: 100vh;
    width: 100vw;
    background-color: #2668FA;
    z-index: 9999999;
    animation: appearinform 0.7s cubic-bezier(0.16, 0.37, 0.64, 0.18);
    animation-fill-mode: forwards;
    direction: rtl;
    right: 0;
}


.submit-popup
{
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgb(255, 255, 255);
    z-index: 9999999;
    animation: appearinform 1s cubic-bezier(0.16, 0.37, 0.64, 0.18);
    animation-fill-mode: forwards;
    direction: rtl;
    display: flex;
    flex-direction: column;
    right: 0;
}
@keyframes appearinform {
    0%
    {
        left: 100vw;
    }
    100%
    {
        left: 0vw;
    }
    
}


.popup-close
{
    cursor: pointer;
    border: 0px;
    background-color: transparent;
    font-size: 3rem;
    color:rgb(0, 87, 255);
    display: flex;
    transition: 0.3s  ease-in-out;
}
.popup-close:hover
{
    transform: rotate(360deg) scale(1.2);
    transition: 0.5s ease-in-out;
}

.div-class-popup
{
    display: flex;
    justify-content: flex-start;
}



.div-main-container-popup-submit
{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

}



.div-main-container-popup-submit-center
{
    margin-top: 10vh;
    height: fit-content;
    position: relative;
    width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: submitfade 0.5s ease-in-out;
    animation-delay: 1s !important;
    animation-fill-mode: forwards;
    flex-direction: column;
}
@keyframes submitfade {
    0%
    {
        opacity: 0;
        top: 10vh;
    }
    
    100%
    {
        opacity:10;
        top: -10vh;
    }
    
}

.pop-up-submit-text-heading
{
    margin-top: 5vh;
    text-align: center;
    font-size: 3rem;
    margin-top: vh;
    padding: 0vh 0vh 0vh 5vh;
    display: flex;
    align-items: center;
}



.pop-up-submit-text
{
    margin-top: 3vh;
    text-align: center;
    font-size: 1.3rem;

}